import { useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { Container } from 'theme-ui';

export const FourZeroFourTemplate = ({ page, renderSections, ...props }) => {
  const router = useRouter();

  const redirectIfDraftProductFromGrouping = useCallback(async () => {
    try {
      const response = await fetch('/json/draft-product-redirects.json');
      const redirectMap = await response.json();
      const redirectProduct =
        redirectMap[window.location.pathname.split('/').pop()];
      if (redirectProduct) {
        router.replace(`/products/${redirectProduct}`);
      }
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  useEffect(() => {
    if (router.isPreview) return;
    const isProductPage = window.location.pathname.startsWith('/products');
    if (isProductPage) redirectIfDraftProductFromGrouping();
  }, []);

  return (
    <Container data-comp="FourZeroFourTemplate">{renderSections()}</Container>
  );
};
